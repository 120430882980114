import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import { faPhone, faMapMarker, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { isMobileOnly } from 'react-device-detect'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { OnClickButton } from '../../components/Button/Button.styled'
import { navigate } from 'gatsby'

const LocationComponent = ({ active, name, phoneNumber, address, city, number, zipcode, email, onClick, slug }) => {
	const [phoneNumberVisible, setPhoneNumberVisible] = useState(false)

	const strippedNumber = phoneNumber.split(',')[0]

	const handleTrigger = (event, action) => {
		// eslint-disable-next-line no-undef
		window.dataLayer.push({
			action: action,
			locationName: name,
			event: event
		})
	}

	const showPhoneNumber = (event, action) => {
		setPhoneNumberVisible(!phoneNumberVisible)

		// eslint-disable-next-line no-undef
		window.dataLayer.push({
			action: action,
			locationName: name,
			event: event
		})
	}

	return (
		<LocationContainer active={active} onClick={onClick}>
			<Name
				dangerouslySetInnerHTML={{
					__html: name
				}}
			/>
			<Divider active={active} />
			<InfoWrapper>
				<AdressInfo>
					<span>
						{address} {number}
					</span>
					<span>{zipcode}</span>
					<span>{city}</span>
				</AdressInfo>
				<CTAWrapper>
					<ContactCTA>
						{isMobileOnly ? (
							<a href={`tel:${phoneNumber}`} onClick={() => handleTrigger('call-location', 'Bel locatie')}>
								<Icon width="1.2rem" height="1.2rem" color="#1B8FCA" margin="0 0 0 10px" icon={faPhone} />
							</a>
						) : (
							<>
								{phoneNumberVisible && (
									<PhoneNumberLabelWrapper>
										{parsePhoneNumber(strippedNumber, { regionCode: 'NL' }).number.national}
									</PhoneNumberLabelWrapper>
								)}
								<a onClick={() => showPhoneNumber('call-location', 'Toon telefoonnummer')}>
									<Icon width="1.2rem" height="1.2rem" color="#1B8FCA" margin="0 0 0 10px" icon={faPhone} />
								</a>
							</>
						)}
						<a href={`mailto:${email}`} onClick={() => handleTrigger('mail-location', 'Mail locatie')}>
							<Icon width="1.2rem" height="1.2rem" color="#1B8FCA" margin="0 0 0 10px" icon={faEnvelope} />
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href={`https://maps.google.com/maps?q=${address}+${number}+${zipcode}`}
							onClick={() => handleTrigger('navigate-location', 'Navigeer locatie')}
						>
							<Icon width="1.2rem" height="1.2rem" color="#1B8FCA" margin="0 0 0 10px" icon={faMapMarker} />
						</a>
					</ContactCTA>
					<ButtonWrapper>
						<OnClickButton
							onClick={() => navigate(`/offerte-aanvragen?location=${slug}`, {})}
							variant="primary"
							padding="12px 30px"
						>
							Offerte
						</OnClickButton>
					</ButtonWrapper>
				</CTAWrapper>
			</InfoWrapper>
		</LocationContainer>
	)
}
export default LocationComponent

const PhoneNumberLabelWrapper = styled.div`
	border-radius: 3px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 5px 10px;
	color: black;
	font-size: 14px;
	position: absolute;
	background-color: #b0bec5;
	bottom: 32px;
	right: 25px;
    min-width: 115px;
    text-align: center;

	&::after {
		content: '';
		position: absolute;
		left: 53%;
		top: 100%;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #b0bec5;
		clear: both;
`

const Name = styled.h3`
	margin: 10px 0;
	font-size: 24px;
`

const CTAWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const LocationContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	border: ${props => (props.active ? '2' : '1')}px solid !important;
	border-color: ${props => (props.active ? '#1B8FCA' : '#A8A8A8')} !important;
	width: 100%;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		width: 49%;
	}

	@media (min-width: ${props => props.theme.sizes.maxDesktopLarge}) {
		width: 100%;
	}

	border-radius: 10px;
`

const Divider = styled.hr`
	width: 100%;
	height: ${props => (props.active ? '2px' : '1px')} !important;
	background: ${props => (props.active ? '#1B8FCA' : '#A8A8A8')} !important;
`

const AdressInfo = styled.div`
	display: flex;
	flex-direction: column;
`

const ContactCTA = styled.div`
	display: flex;
	position: relative;
	align-self: flex-end;
`

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	align-items: flex-start;
	gap: 5px;
`

const ButtonWrapper = styled.div`
	margin-top: 15px;
`
