import React, { useEffect, useState } from 'react'
import { compose, withProps } from 'recompose'
import {
	LocationName,
	LocationAddressLine,
	LocationWrapper,
	ContactActions,
	InfoWindowContainer,
	PhoneNumberLabelWrapper
} from './Map.styled'
import Icon from '../Icon'
import { faPhone, faMapMarker, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { withGoogleMap, withScriptjs, GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import { isMobileOnly } from 'react-device-detect'
import { parsePhoneNumber } from 'awesome-phonenumber'

const MapComponent = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_MAP_API_KEY}`,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `100%` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withScriptjs,
	withGoogleMap
)(({ locations, active, setIndex }) => {
	const handleTrigger = (event, action, name) => {
		// eslint-disable-next-line no-undef
		window.dataLayer.push({
			action: action,
			locationName: name,
			event: event
		})
	}

	const [phoneNumberVisible, setPhoneNumberVisible] = useState({ isVisible: false, mapItem: null })

	const showPhoneNumber = (event, action, location) => {
		setPhoneNumberVisible({ isVisible: !phoneNumberVisible.isVisible, mapItem: location.id })

		// eslint-disable-next-line no-undef
		window.dataLayer.push({
			action: action,
			locationName: location.postContent.name,
			event: event
		})
	}

	return (
		<GoogleMap defaultZoom={8} defaultCenter={{ lat: 52.1554703618375, lng: 5.386920866003357 }}>
			{locations.map((location, index) => {
				return (
					<Marker
						key={location.id}
						position={{ lat: parseFloat(location.postContent.lat), lng: parseFloat(location.postContent.lng) }}
						onClick={() => setIndex(index, location.postContent.name)}
					>
						{active === index && (
							<InfoWindow>
								<InfoWindowContainer>
									<LocationWrapper>
										<LocationName
											dangerouslySetInnerHTML={{
												__html: location.postContent.name
											}}
										/>
										<LocationAddressLine>
											{location.postContent.address} {location.postContent.number}
										</LocationAddressLine>
										<LocationAddressLine>{location.postContent.zipcode}</LocationAddressLine>
										<LocationAddressLine>{location.postContent.city}</LocationAddressLine>
									</LocationWrapper>
									<ContactActions>
										{isMobileOnly ? (
											<a
												href={`tel:${location.postContent.phoneNumber}`}
												onClick={() => handleTrigger('call-location', 'Bel locatie', location.postContent.name)}
											>
												<Icon width="1.2rem" height="1.2rem" color="#1B8FCA" margin="0 10px 0 0" icon={faPhone} />
											</a>
										) : (
											<>
												{phoneNumberVisible.isVisible && location.id === phoneNumberVisible.mapItem && (
													<PhoneNumberLabelWrapper>
														{
															parsePhoneNumber(location.postContent.phoneNumber.split(',')[0], { regionCode: 'NL' })
																.number.national
														}
													</PhoneNumberLabelWrapper>
												)}
												<a onClick={() => showPhoneNumber('call-location', 'Toon telefoonnummer', location)}>
													<Icon width="1.2rem" height="1.2rem" color="#1B8FCA" margin="0 10px 0 0" icon={faPhone} />
												</a>
											</>
										)}

										<a
											href={`mailto:${location.postContent.email}`}
											onClick={() => handleTrigger('mail-location', 'Mail locatie', location.postContent.name)}
										>
											<Icon width="1.2rem" height="1.2rem" color="#1B8FCA" margin="0 10px 0 0" icon={faEnvelope} />
										</a>
										<a
											target="_blank"
											rel="noreferrer"
											href={`https://maps.google.com/maps?q=${location.postContent.address}+${location.postContent.number}+${location.postContent.zipcode}`}
											onClick={() => handleTrigger('navigate-location', 'Navigeer locatie', location.postContent.name)}
										>
											<Icon width="1.2rem" height="1.2rem" color="#1B8FCA" margin="0 10px 0 0" icon={faMapMarker} />
										</a>
									</ContactActions>
								</InfoWindowContainer>
							</InfoWindow>
						)}
					</Marker>
				)
			})}
		</GoogleMap>
	)
})

export default MapComponent
