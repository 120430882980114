import styled from 'styled-components'

import Section from '../../Section'

export const BlockLocationsSection = styled(Section)`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`

export const LocationsContainer = styled.div`
	display: flex;
	width: 100%;
	cursor: pointer;
	gap: 10px;
	flex-direction: column;
	margin-bottom: 10px;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		flex-flow: wrap;
		justify-content: center;
	}

	@media (min-width: ${props => props.theme.sizes.maxDesktopLarge}) {
		flex-direction: column;
		max-width: 450px;
	}
`

export const GmWrapper = styled.div`
	display: block;
	height: 800px;

	width: 100%;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		max-width: 100%;
	}

	@media (min-width: ${props => props.theme.sizes.maxDesktopLarge}) {
		max-width: 650px;
	}
`
