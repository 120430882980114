import { useStaticQuery, graphql } from 'gatsby'

export const useTuningLocationPosts = () => {
	const data = useStaticQuery(
		graphql`
			query {
				allWpTuningLocation(sort: { fields: [date], order: ASC }, limit: 18) {
					edges {
						node {
							databaseId
							slug
							id
							date
							uri
							postContent {
								address
								city
								email
								fieldGroupName
								name
								number
								phoneNumber
								zipcode
								lat
								lng
							}
						}
					}
				}
			}
		`
	)
	return data.allWpTuningLocation.edges.map(edge => edge.node)
}
