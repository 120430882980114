import React, { useState } from 'react'
import { useTuningLocationPosts } from '../../../hooks/useTuningLocationPosts'
import LocationComponent from '../../../components/LocationComponent'

import { BlockLocationsSection, LocationsContainer, GmWrapper } from './BlockTuningLocations.styled'
import MapComponent from '../../Map/MapComponent'

const BlockLocations = data => {
	const [activeIndex, setActiveIndex] = useState(0)
	const tuningLocationsData = useTuningLocationPosts()

	const checkLocation = index => {
		setActiveIndex(index)
		// eslint-disable-next-line no-undef
		// window.dataLayer.push({
		// 	action: 'Bekijk locatie',
		// 	locationName: locationName,
		// 	event: 'check-location'
		// })
	}

	return (
		<BlockLocationsSection paddingTop={data.paddingTop} paddingBottom={data.paddingBottom}>
			<LocationsContainer>
				{tuningLocationsData &&
					tuningLocationsData
						.slice(0, data.maximumPostsCount)
						.map((tuningLocation, index) => (
							<LocationComponent
								name={tuningLocation.postContent.name}
								address={tuningLocation.postContent.address}
								number={tuningLocation.postContent.number}
								zipcode={tuningLocation.postContent.zipcode}
								city={tuningLocation.postContent.city}
								email={tuningLocation.postContent.email}
								phoneNumber={tuningLocation.postContent.phoneNumber}
								onClick={() => checkLocation(index)}
								active={activeIndex === index}
								slug={tuningLocation.slug}
								key={tuningLocation.id}
							/>
						))}
			</LocationsContainer>
			<GmWrapper>
				<MapComponent locations={tuningLocationsData} active={activeIndex} setIndex={checkLocation} />
			</GmWrapper>
		</BlockLocationsSection>
	)
}

export default BlockLocations
