import styled from 'styled-components'

export const InfoWindowContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 200px;
`

export const LocationName = styled.span`
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 5px;
`
export const LocationAddressLine = styled.span`
	margin-bottom: 2px;
`

export const LocationWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const ContactActions = styled.div`
	display: flex;
	flex-direction: row;
	margin: 5px 0;
`

export const PhoneNumberLabelWrapper = styled.div`
	border-radius: 3px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 5px 10px;
	color: black;
	font-size: 14px;
	position: absolute;
	background-color: #b0bec5;
    bottom: 45px;
    right: 109px;
	min-width: 115px;
	text-align: center;

	&::after {
		content: '';
		position: absolute;
		left: 4%;
		top: 100%;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #b0bec5;
		clear: both;
`
